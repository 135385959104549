import React from "react"

interface Props extends React.HTMLProps<HTMLInputElement> {}

export const Input = (props: Props) => (
    <input
        type="text"
        {...props}
        className={
            "block w-full rounded-md border-gray-300 shadow-sm focus:border-cyan-300 focus:ring focus:ring-cyan-200 focus:ring-opacity-50" +
            props.className
        }
    />
)
