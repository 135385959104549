import * as React from "react"
import { useEffect, useState } from "react"
import { SecureLayout } from "../ui/SecureLayout"
import { useDispatch, useSelector } from "react-redux"
import { fetchTasks } from "../redux/actions"
import { TaskView } from "./TaskView"
import { findTasks } from "../redux/selectors"
import { TaskCreate } from "./TaskCreate"

export const TaskList = () => {
    const tasks = useSelector(findTasks)
    const dispatch = useDispatch()

    const [activeTask, changeActiveTask] = useState()

    useEffect(() => {
        dispatch(fetchTasks())
    }, [])

    return (
        <SecureLayout>
            <TaskCreate />
            <div className="flex flex-wrap overflow-hidden xl:-mx-2">
                {tasks.map((task) => (
                    <div
                        key={task.uuid}
                        className="w-full lg:w-1/2 my-2 px-2"
                        onClick={() => changeActiveTask(task.uuid)}
                    >
                        <TaskView
                            task={task}
                            active={activeTask === task.uuid}
                        />
                    </div>
                ))}
            </div>
        </SecureLayout>
    )
}
