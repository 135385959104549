import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { createTask } from "../redux/actions"
import { Input } from "../ui/Input"

export const TaskCreate = () => {
    const dispatch = useDispatch()
    const [value, updateValue] = useState("")

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                updateValue("")
                dispatch(createTask(value))
            }}
        >
            <Input
                className="w-full text-center text-lg"
                type="text"
                placeholder="add new task"
                value={value}
                onChange={(event) =>
                    updateValue((event.target as HTMLInputElement).value)
                }
            />
        </form>
    )
}
