import history from "history"
import { DateString, Task, UUID } from "../domain/Task"
import { Dispatch } from "react"

export const ACTION_LOGIN = "user.login"
export const ACTION_LOGIN_FAILURE = "user.login.failure"
export const ACTION_LOGOUT = "user.logout"

export const ACTION_TASKS_FETCHED = "tasks.fetched"
export const ACTION_TASK_UPDATED = "task.updated"

const localStorageJwtKey = "app.auth.token"

export const login = () => ({ type: ACTION_LOGIN })
export const loginFail = (errorMessage: string) => ({
    type: ACTION_LOGIN_FAILURE,
    payload: errorMessage,
})
export const logout = () => ({ type: ACTION_LOGOUT })

export const performLogout = () => (dispatch: (action: any) => any) => {
    localStorage.removeItem(localStorageJwtKey)

    dispatch(logout())
}

export const performLogin = (password: string, history?: history.History) => (
    dispatch: (action: any) => any
) => {
    if (!history) {
        return
    }

    fetch(process.env.REACT_APP_API_ADDRESS + "/login_check", {
        body: JSON.stringify({ username: "tasker", password }),
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((response) => response.json())
        .then((data) => {
            if ("token" in data) {
                localStorage.setItem(localStorageJwtKey, data.token)
                history.push("/")
                dispatch(login())

                return
            }

            dispatch(loginFail(data.message || "unknown error"))
        })
        .catch((error) => dispatch(loginFail(error)))
}

export const autologin = (history?: history.History) => (
    dispatch: (action: any) => void
) => {
    if (!history) {
        return
    }

    const jwtKey = localStorage.getItem(localStorageJwtKey)

    if (jwtKey && jwtKey.length > 0) {
        dispatch(login())

        history.push("/")
    }
}

export const fetchTasks = () => (dispatch: Dispatch<any>) => {
    api(dispatch, "/tasks", {
        method: "GET",
    })
        .then((data) => {
            dispatch(tasksFetched(data))
        })
        .catch((error) => console.error(error))
}

export const tasksFetched = (tasks: Task<DateString>[]) => ({
    type: ACTION_TASKS_FETCHED,
    payload: tasks,
})

export const completeTask = (uuid: UUID) => (dispatch: Dispatch<any>) => {
    api(dispatch, `/tasks/${uuid}/complete`, {
        method: "POST",
    })
        .then((data) => {
            dispatch(taskUpdated(data))
        })
        .catch((error) => console.error(error))
}

export const createTask = (task: string) => (dispatch: Dispatch<any>) => {
    api(dispatch, `/tasks/add`, {
        method: "POST",
        body: JSON.stringify({ task }),
    })
        .then(() => dispatch(fetchTasks()))
        .catch((error) => console.error(error))
}

export const updateTask = (uuid: UUID, fields: { [field: string]: string }) => (
    dispatch: Dispatch<any>
) => {
    api(dispatch, `/tasks/${uuid}/update`, {
        method: "POST",
        body: JSON.stringify(fields),
    })
        .then((data) => {
            dispatch(taskUpdated(data))
        })
        .catch((error) => console.error(error))
}

export const taskUpdated = (task: Task<DateString>) => ({
    type: ACTION_TASK_UPDATED,
    payload: task,
})

const api = (
    dispatch: Dispatch<any>,
    input: string,
    init: RequestInit | undefined
) => {
    const jwtKey = localStorage.getItem(localStorageJwtKey)

    init = {
        ...init,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + jwtKey,
            ...init?.headers,
        },
    }

    return fetch(process.env.REACT_APP_API_ADDRESS + input, init).then(
        (response) => {
            if (response.status === 401) {
                dispatch(performLogout())

                return Promise.reject("expired JWT token")
            }

            return response.json()
        }
    )
}
