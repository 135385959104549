import { applyMiddleware, compose, createStore } from "redux"
import {
    ACTION_LOGIN,
    ACTION_LOGIN_FAILURE,
    ACTION_LOGOUT,
    ACTION_TASK_UPDATED,
    ACTION_TASKS_FETCHED,
} from "./actions"
import thunk from "redux-thunk"
import { DateString, Task, TaskStatus } from "../domain/Task"
import { DateTime } from "luxon"

export interface ApplicationState {
    user: {
        authenticated: boolean
        authenticationError?: string
    }
    flashMessages: Array<{
        level: "info" | "warning" | "error"
        message: string
    }>
    tasks: {
        byUuid: TaskList
    }
    filter: TaskFilterCriteria
}

interface TaskFilterCriteria {
    status: TaskStatus[]
}

interface TaskList {
    [uuid: string]: Task<DateTime>
}

const defaultState: ApplicationState = {
    user: {
        authenticated: false,
    },
    flashMessages: [],
    tasks: { byUuid: {} },
    filter: { status: [TaskStatus.PENDING] },
}

const reducer = (
    state: ApplicationState = defaultState,
    action: any
): ApplicationState => {
    switch (action.type) {
        case ACTION_LOGIN:
            return {
                ...state,
                user: { authenticated: true, authenticationError: undefined },
            }
        case ACTION_LOGIN_FAILURE:
            return {
                ...state,
                user: {
                    authenticated: false,
                    authenticationError: action.payload,
                },
            }
        case ACTION_LOGOUT:
            return {
                ...defaultState,
            }

        case ACTION_TASKS_FETCHED:
            const tasks: TaskList = {}

            action.payload.forEach((task: Task<DateString>) => {
                tasks[task.uuid] = convertTask(task)
            })

            return {
                ...state,
                tasks: {
                    byUuid: tasks,
                },
            }

        case ACTION_TASK_UPDATED:
            const { payload: task } = action

            return {
                ...state,
                tasks: {
                    byUuid: {
                        ...state.tasks.byUuid,
                        [task.uuid]: convertTask(task),
                    },
                },
            }
    }

    return state
}

const composeEnhancer =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(
    reducer,
    composeEnhancer(applyMiddleware(thunk))
)

const convertTask = (task: Task<DateString>): Task<DateTime> => {
    return {
        ...task,
        wait: task.wait ? DateTime.fromISO(task.wait) : null,
        due: task.due ? DateTime.fromISO(task.due) : null,
        annotations: task.annotations.map((annotation) => ({
            ...annotation,
            entry: DateTime.fromISO(annotation.entry),
        })),
    }
}
