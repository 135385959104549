import React, { useState } from "react"
import { Redirect, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { performLogin } from "./redux/actions"
import { ApplicationState } from "./redux/store"
import { Input } from "./ui/Input"
import { Button } from "./ui/Button"

export const Login = () => {
    const [password, setPassword] = useState("")

    const errorMessage = useSelector(
        (state: ApplicationState) => state.user.authenticationError
    )
    const authenticated = useSelector(
        (state: ApplicationState) => state.user.authenticated
    )

    const dispatch = useDispatch()
    const history = useHistory()

    if (authenticated) {
        return <Redirect to={{ pathname: "/" }} />
    }

    return (
        <div className="container mx-auto">
            <div className="mx-auto max-w-lg my-2 rounded bg-white shadow p-8 text-center">
                {errorMessage ? (
                    <div className="bg-red-100 text-red-600 p-2 rounded m-2 shadow">
                        {errorMessage}
                    </div>
                ) : null}
                <form
                    onSubmit={(ev) => {
                        ev.preventDefault()
                        dispatch(performLogin(password, history))
                    }}
                >
                    <Input
                        type="password"
                        name="password"
                        placeholder="enter ANY key"
                        onChange={(ev) =>
                            setPassword((ev.target as HTMLInputElement).value)
                        }
                    />
                    <Button type="primary" fullWidth>
                        sign in
                    </Button>
                </form>
            </div>
        </div>
    )
}
