import React from "react"

interface Props extends React.PropsWithChildren<any> {
    type: "primary" | "secondary" | "tertiary"
    onClick?: () => void
    fullWidth?: boolean
}

export const Button = ({
    type,
    onClick,
    fullWidth = false,
    children,
}: Props) => {
    let className =
        "transition rounded hover:shadow-md text-gray-500 px-2 py-1 m-1 hover:text-gray-600"

    switch (type) {
        case "primary":
            className =
                "transition rounded shadow-sm hover:shadow-md bg-cyan-500 text-white px-2 py-1 hover:bg-cyan-600"
            break
        case "secondary":
            className =
                "transition rounded shadow-sm hover:shadow-md bg-gray-100 text-gray-500 px-2 py-1 hover:text-gray-600"
            break
    }

    if (fullWidth) {
        className += " w-full"
    }

    return (
        <button className={className} onClick={onClick}>
            {children}
        </button>
    )
}
