import * as React from "react"
import { Route, Redirect, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { ApplicationState } from "./redux/store"
import { autologin } from "./redux/actions"

// @ts-ignore
export const PrivateRoute = ({ children, ...rest }) => {
    const authenticated = useSelector(
        (state: ApplicationState) => state.user.authenticated
    )

    const dispatch = useDispatch()
    const history = useHistory()

    if (!authenticated) {
        dispatch(autologin(history))
    }

    return (
        <Route
            {...rest}
            render={() => {
                return authenticated ? (
                    children
                ) : (
                    <Redirect to={{ pathname: "/login" }} />
                )
            }}
        />
    )
}
