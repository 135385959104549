import { ApplicationState } from "./store"
import { Task } from "../domain/Task"
import { DateTime } from "luxon"

export const findTasks = (state: ApplicationState): Task<DateTime>[] =>
    Object.keys(state.tasks.byUuid)
        .map((uuid) => state.tasks.byUuid[uuid])
        .sort((a, b) => b.urgency - a.urgency)
        .filter((task) => {
            if (state.filter.status.length > 0) {
                if (!state.filter.status.includes(task.status)) {
                    return false
                }
            }

            return true
        })
