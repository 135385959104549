import React from "react"
import { Task } from "../domain/Task"
import ReactMarkdown from "react-markdown"
import { DateTime } from "luxon"
import { Clock } from "../icons/Clock"
import { Briefcase } from "../icons/Briefcase"
import { Button } from "../ui/Button"
import { useDispatch } from "react-redux"
import { completeTask, updateTask } from "../redux/actions"
import { Editable } from "../ui/Editable"

interface Props {
    task: Task<DateTime>
    active: boolean
}

export const TaskView = ({ task, active = false }: Props) => {
    const dispatch = useDispatch()

    const className = active
        ? "transition rounded bg-white shadow border border-transparent ring-2 ring-cyan-300 flow-root text-gray-700"
        : "transition rounded bg-white shadow border border-transparent flow-root text-gray-700 h-full"

    return (
        <div className={className}>
            <div className="px-2 py-4">
                <div className="flex items-start">
                    {active ? (
                        <div className="flex-grow">
                            <Editable
                                onSubmit={(description) =>
                                    dispatch(
                                        updateTask(task.uuid, { description })
                                    )
                                }
                                value={task.description}
                                className="text-lg mb-2 font-bold"
                            >
                                {task.description}
                            </Editable>
                        </div>
                    ) : (
                        <h1 className="flex-grow text-lg mb-2 truncate font-bold">
                            {task.description}
                        </h1>
                    )}
                    {
                        {
                            L: (
                                <span className="flex-none text-gray-600 bg-gray-200 px-3 py-1 float-right ml-1 text-sm rounded-md">
                                    low
                                </span>
                            ),
                            M: (
                                <span className="flex-none text-orange-600 bg-orange-200 px-3 py-1 float-right ml-1 text-sm rounded-md">
                                    medium
                                </span>
                            ),
                            H: (
                                <span className="flex-none text-red-600 bg-red-200 px-3 py-1 float-right ml-1 text-sm rounded-md">
                                    high
                                </span>
                            ),
                        }[task.priority]
                    }
                </div>
                <ul className="flex">
                    {task.tags.map((tag) => (
                        <li
                            className="w-auto rounded px-3 mr-1 bg-blue-100 text-blue-600 text-sm"
                            key={tag}
                        >
                            {tag}
                        </li>
                    ))}
                </ul>
                {task.annotations.length > 0 ? (
                    <div className="mt-2">
                        {task.annotations.map((annotation) => (
                            <div key={annotation.entry.toISO()}>
                                <span className="float-right text-sm text-gray-500">
                                    {annotation.entry.toISODate()}
                                </span>
                                <div className="markdown">
                                    <ReactMarkdown
                                        source={annotation.description}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
                {task.due ? (
                    <div className="mt-2">
                        <Clock /> <span className="text-gray-500">due</span>{" "}
                        {task.due.toISODate()}
                    </div>
                ) : null}

                <div className="mt-2">
                    <Briefcase /> <span className="text-gray-500">status</span>{" "}
                    {task.status}
                </div>

                {task.project ? (
                    <div className="flex gap-2 items-center mt-2">
                        <div className="flex-none">
                            <Briefcase />{" "}
                            <span className="text-gray-500">project</span>
                        </div>

                        <div className="flex-grow">
                            {active ? (
                                <Editable
                                    onSubmit={(project) =>
                                        dispatch(
                                            updateTask(task.uuid, { project })
                                        )
                                    }
                                    value={task.project}
                                >
                                    {task.project}
                                </Editable>
                            ) : (
                                <span>{task.project}</span>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>

            {active && (
                <div className="flex justify-between p-2 bg-cyan-50 shadow-inner">
                    <Button
                        type="secondary"
                        onClick={() =>
                            dispatch(updateTask(task.uuid, { wait: "1week" }))
                        }
                    >
                        postpone
                    </Button>

                    <Button
                        type="primary"
                        onClick={() => dispatch(completeTask(task.uuid))}
                    >
                        mark done
                    </Button>
                </div>
            )}
        </div>
    )
}
