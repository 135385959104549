import { DateTime } from "luxon"

export interface Task<DATE_FORMAT extends DateTime | DateString> {
    uuid: UUID
    description: string
    wait: DATE_FORMAT | null
    due: DATE_FORMAT | null
    tags: string[]
    annotations: Annotation<DATE_FORMAT>[]
    urgency: number
    priority: TaskPriority
    project: string
    status: TaskStatus
}

interface Annotation<DATE_FORMAT> {
    entry: DATE_FORMAT
    description: string
}

export type DateString = string

export type UUID = string

export enum TaskPriority {
    LOW = "L",
    MEDIUM = "M",
    HIGH = "H",
}

export enum TaskStatus {
    PENDING = "pending",
    COMPLETED = "completed",
    DELETED = "deleted",
    WAITING = "waiting",
    RECURRING = "recurring",
}
