import React, { PropsWithChildren, useState } from "react"
import { Input } from "./Input"

interface Props extends PropsWithChildren<any> {
    onSubmit: (value: string) => void
    value: string
    className?: string
}

export const Editable = ({ onSubmit, children, value, className }: Props) => {
    const [currentValue, updateValue] = useState(value)

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                onSubmit(currentValue)
            }}
        >
            <Input
                type="text"
                value={currentValue}
                onChange={(event) =>
                    updateValue((event.target as HTMLInputElement).value)
                }
                className={className}
            />
        </form>
    )
}
