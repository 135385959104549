import * as React from "react"
import { performLogout } from "../redux/actions"
import { useDispatch } from "react-redux"
import { Button } from "./Button"

interface Props {
    children: React.ReactNode
}

export const SecureLayout = ({ children }: Props) => {
    const dispatch = useDispatch()

    return (
        <div className="container bp-4 m-auto">
            <div className="clearfix">
                <div className="float-right">
                    <Button
                        type="tertiary"
                        onClick={() => dispatch(performLogout())}
                    >
                        logout
                    </Button>
                </div>
                <h1 className="text-2xl mb-4 font-hairline font-serif">
                    <i>Task-Dancer</i>
                </h1>
            </div>
            {children}
        </div>
    )
}
