import React, { useEffect } from "react"
import { Login } from "./Login"
import "./tailwind.output.css"
import { Route, Switch, BrowserRouter, useHistory } from "react-router-dom"
import { PrivateRoute } from "./PrivateRoute"
import { TaskList } from "./components/TaskList"
import { useDispatch, useSelector } from "react-redux"
import { autologin } from "./redux/actions"
import { ApplicationState } from "./redux/store"

export const App = () => {
    const dispatch = useDispatch()

    const authenticated = useSelector(
        (state: ApplicationState) => state.user.authenticated
    )
    const history = useHistory()

    useEffect(() => {
        if (!authenticated) {
            dispatch(autologin(history))
        }
    })

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login">
                    <Login />
                </Route>
                <PrivateRoute exact path="/">
                    <TaskList />
                </PrivateRoute>
                <PrivateRoute exact>
                    <TaskList />
                </PrivateRoute>
            </Switch>
        </BrowserRouter>
    )
}
